<template>
  <div>
    <select v-model="selectedSbPresetId">
      <option :value="sbp" v-for="sbp in Object.keys(presets)" :key="sbp">
        {{ sbp }}
      </option>
    </select>
    <input class="ml-1" :disabled="!isNewPreset" v-model="id" type="text" placeholder="New Preset Name"/>
    <div>Total: {{(presets[selectedSbPresetId] || {}).length}}</div>
    <multiselect v-model=           "slugs"
                 @tag=              "addSlugs"
                 label=             "slug"
                 track-by=          "slug"
                 :multiple=         "true"
                 :options=          "options"
                 :taggable=         "true"
                 :disabled=         "false"
                 :clearOnSelect=    "false"
                 :closeOnSelect=    "false"
                 :clear-on-select=  "false"
    />
    <div class="mt-1">
      <NetworkButton  ref=              "updateButton"
                      :key=             "selectedSbPresetId + 'UpdateCreate'"
                      :path=            "`site_blacklist_presets/${isNewPreset ? '' : selectedSbPresetId}`"
                      :method=          "isNewPreset ? 'POST' : 'PUT'"
                      variant=          "success"
                      @clicked=         "setPayload"
                      :disabled=        "false"
                      @onSuccess=       "onSuccess"
                      :feedbackObject=  "feedbackObject"
      >{{ isNewPreset ? 'Create' : 'Update' }}
      </NetworkButton>
      <NetworkButton  ref=              "deleteButton"
                      :key=             "selectedSbPresetId + 'Delete'"
                      class=            "ml-1"
                      :path=            "`site_blacklist_presets/${selectedSbPresetId}`"
                      method=           "DELETE"
                      variant=          "danger"
                      :disabled=        "isNewPreset"
                      @onSuccess=       "onDelSuccess"
                      :feedbackObject=  "feedbackObject"
      >Delete
      </NetworkButton>
    </div>
  </div>
</template>

<script>
  import genericRequest from '../../../components/generic/NetworkRequest.js'
  import Multiselect from "vue-multiselect";

  export default {
    mixins: [genericRequest],
    props: {
      feedbackObject: Object,
    },
    data() {
      return {
        id:                 null,
        slugs:              [],
        options:            [],
        presets:            {},
        isNewPreset:        true,
        blacklistNetwork:   this.createNetworkObject(),
        selectedSbPresetId: 'Create New:'
      }
    },
    mounted() {
      this.getSitesBlacklistPresets()
    },
    methods: {
      setPayload(networkRequest) {
        let payload = { slugs: this.slugs.map(s=>s.slug) };
        if (this.isNewPreset){
          payload.id        = this.id;
          payload.platform  = 'taboola';
        }
        networkRequest.payload = payload
      },
      getSitesBlacklistPresets() {
        this.request('get', 'site_blacklist_presets/index/taboola', this.blacklistNetwork)
          .then(res => {
            let p = { ['Create New:']: [] };
            res.forEach(d => p[d._id] = d.slugs);
            this.presets = p;
            this.updateSlugs()
          })
      },
      onSuccess() {
        this.getSitesBlacklistPresets();
        this.feedbackObject.text = `Successfully ${this.isNewPreset ? 'created' : 'updated'} preset ${this.isNewPreset ? this.id : this.selectedSbPresetId }`
      },
      onDelSuccess() {
        this.getSitesBlacklistPresets();
        this.feedbackObject.text = `Successfully deleted ${this.selectedSbPresetId}`;
        this.selectedSbPresetId = 'Create New:'
      },
      addSlugs(newSlugs) {
        let slugs     = newSlugs.split(',').map(s=>({slug: s}));
        this.slugs    = this.slugs.concat(slugs);
        this.options  = this.options.concat(slugs)
      },
      updateSlugs() {
        this.slugs    = this.presets[this.selectedSbPresetId].map(s=>({slug: s}));
        this.options  = this.presets[this.selectedSbPresetId].map(s=>({slug: s}));
      }
    },
    watch: {
      selectedSbPresetId: function(val) {
        this.isNewPreset = val === 'Create New:';
        this.updateSlugs()
      },
      'blacklistNetwork.response.status': function(status) {
        this.feedbackObject.class = status;
        this.input = null
      },
      'blacklistNetwork.response.message': function(message) {
        this.feedbackObject.text = message
      },
    },
    components: { Multiselect }
  }
</script>
<style>
  .multiselect__content-wrapper {
    width: 80%;
    margin-left: 4rem
  }
</style>
